<template>
  <div
    style="width: calc(100% + 40px); height: 100%; margin: 0 -20px -20px -20px"
  >
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />
    <div class="edition">
      <div v-for="(item, index) in versionData" :key="index">
        <div class="edition_android" v-if="item.flag == 0">
          <div class="android">安卓版本管理</div>
          <!-- 点击更新前 -->
          <div v-if="!showAndroidVersionContent">
            <div class="content">
              <p>
                当前版本：<span>{{ item.versionNum }}</span>
              </p>
              <p>
                下载地址：<span>{{ item.fileUrl }}</span>
              </p>
              <p>
                更新内容：<span>{{ item.content }}</span>
              </p>
              <div>
                审核状态：<el-radio
                  disabled
                  v-model="examineBtnStatusAnd"
                  :label="true"
                  >审核中</el-radio
                >
                <el-radio disabled v-model="examineBtnStatusAnd" :label="false"
                  >审核通过</el-radio
                >
              </div>
              <el-checkbox
                v-model="checked"
                label="是否强制更新"
                disabled
              ></el-checkbox>
            </div>
            <div class="show_btn">
              <el-button
                class="btn"
                style="border: none"
                type="primary"
                round
                @click="changeStatusInput(0)"
                >更 新</el-button
              >
            </div>
          </div>
          <!-- 点击更新后 -->
          <div v-if="showAndroidVersionContent">
            <div class="content">
              <p>
                当前版本：<el-input v-model="form.android.version"></el-input>
              </p>
              <p>下载地址：<el-input v-model="form.android.url"></el-input></p>
              <p style="margin-top: 8px">
                更新内容：<el-input
                  type="textarea"
                  placeholder="不超过80字（分行最多三行）"
                  v-model="form.android.textarea"
                  maxlength="80"
                  show-word-limit
                >
                </el-input>
              </p>
              <div>
                审核状态：<el-radio v-model="examineBtnStatusAnd" :label="true"
                  >审核中</el-radio
                >
                <el-radio v-model="examineBtnStatusAnd" :label="false"
                  >审核通过</el-radio
                >
              </div>
              <el-checkbox v-model="showChecked">是否强制更新</el-checkbox>
            </div>
            <div class="btn_showed">
              <el-button round @click="cancelBtn(0)">取消</el-button>
              <el-button type="primary" round @click="trueAndroidBtn(0)"
                >确认</el-button
              >
            </div>
          </div>
        </div>

        <div class="edition_ios" v-else-if="item.flag == 1">
          <div class="ios">IOS版本管理</div>
          <!-- 点击更新前 -->
          <div v-if="!showIosVersionContent">
            <div class="content">
              <p>
                当前版本：<span>{{ item.versionNum }}</span>
              </p>
              <p>
                更新内容：<span>{{ item.content }}</span>
              </p>
              <div>
                审核状态：<el-radio
                  disabled
                  v-model="examineBtnStatus"
                  :label="true"
                  >审核中</el-radio
                >
                <el-radio disabled v-model="examineBtnStatus" :label="false"
                  >审核通过</el-radio
                >
              </div>
              <el-checkbox
                v-model="checkedIOS"
                label="是否强制更新"
                disabled
              ></el-checkbox>
            </div>
            <div class="show_btn">
              <el-button
                style="
                  background-image: linear-gradient(-129deg, #ff9d00, #ffc600);
                  border: none;
                "
                class="btn"
                type="primary"
                round
                @click="changeStatusInput(1)"
                >更 新</el-button
              >
            </div>
          </div>
          <!-- 点击更新后 -->
          <div v-if="showIosVersionContent">
            <div class="content">
              <p style="margin-bottom: 5px">
                当前版本：<el-input v-model="form.ios.version"></el-input>
              </p>
              <p style="margin-bottom: 12px">
                更新内容：<el-input
                  type="textarea"
                  placeholder="不超过80字（分行最多三行）"
                  v-model="form.ios.textarea"
                  maxlength="80"
                  show-word-limit
                ></el-input>
              </p>
              <div>
                审核状态：<el-radio v-model="examineBtnStatus" :label="true"
                  >审核中</el-radio
                >
                <el-radio v-model="examineBtnStatus" :label="false"
                  >审核通过</el-radio
                >
              </div>
              <el-checkbox v-model="showIOSChecked">是否强制更新</el-checkbox>
            </div>
            <div class="btn_showed">
              <el-button round @click="cancelBtn(1)">取消</el-button>
              <el-button type="primary" round @click="trueAndroidBtn(1)"
                >确认</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
export default {
  components: {
    Breadcrumb,
  },
  inject: ["reload"],
  data() {
    return {
      BreadcrumbCon: [
        {
          name: "日常管理",
        },
        {
          name: "版本管理",
        },
      ], // 面包屑数据

      versionData: [],
      form: {
        android: {
          version: "", // 当前版本
          url: "", // 下载地址
          textarea: "", // 更新内容
        },
        ios: {
          version: "", // 当前版本
          textarea: "", // 更新内容
        },
      },
      checked: false, // 是否强制更新--android
      checkedIOS: false, // 是否强制更新--ios

      showChecked: false, // 点击切换后-是否强制更新--android
      showIOSChecked: false, // 点击切换后-是否强制更新--ios

      showAndroidVersionContent: false, // 点击更新按钮切换输入框--android
      showIosVersionContent: false, // 点击更新按钮切换输入框--ios

      examineBtnStatus: true,
      examineBtnStatusAnd: true,
    };
  },

  mounted() {
    const opt = {
      url: reqUrl.getVersion,
      method: "POST",
      resFunc: (res) => {
        this.versionData = res.data.data;
        res.data.data.map((item) => {
          if (item.flag == 0) {
            this.form.android.version = item.versionNum;
            this.form.android.url = item.fileUrl;
            this.form.android.textarea = item.content;
            this.checked = item.forceUpdate;
            this.examineBtnStatusAnd = item.isAuditVersion;
            if (item.forceUpdate == "0") {
              this.checked = false;
            } else if (item.forceUpdate == "1") {
              this.checked = true;
            }
          }
          if (item.flag == 1) {
            this.form.ios.version = item.versionNum;
            this.form.ios.textarea = item.content;
            this.checkedIOS = item.forceUpdate;
            this.examineBtnStatus = item.isAuditVersion;
            if (item.forceUpdate == "0") {
              this.checkedIOS = false;
            } else if (item.forceUpdate == "1") {
              this.checkedIOS = true;
            }
          }
        });
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
      },
    };
    request(opt);
  },
  methods: {
    // 点击更新按钮
    changeStatusInput(index) {
      if (index == 0) {
        this.showAndroidVersionContent = true;
      } else if (index == 1) {
        this.showIosVersionContent = true;
      }
    },

    // 取消按钮
    cancelBtn(index) {
      if (index == 0) {
        this.showAndroidVersionContent = false;
      } else if (index == 1) {
        this.showIosVersionContent = false;
      }
    },

    // 确认按钮
    trueAndroidBtn(index) {
      this.$confirm("您确认填写完毕，要提交吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (index == 0) {
            const opt = {
              url: reqUrl.addVersion,
              method: "POST",
              params: JSON.stringify({
                content: this.form.android.textarea,
                fileUrl: this.form.android.url,
                flag: "0",
                forceUpdate: this.showChecked,
                versionNum: this.form.android.version,
                auditVersion: this.examineBtnStatusAnd,
              }),
              resFunc: (res) => {
                if (res.data.code == 999) {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message,
                  });
                } else {
                  this.$message({
                    type: "success",
                    message: "修改成功!",
                  });
                  this.showAndroidVersionContent = false;
                  this.refresh();
                }
              },
              errFunc: (err) => {
                this.$notify.error({
                  title: "错误",
                  message: err,
                });
                console.log(err, "err1");
              },
            };
            request(opt);
          } else if (index == 1) {
            const opt = {
              url: reqUrl.addVersion,
              method: "POST",
              params: JSON.stringify({
                content: this.form.ios.textarea,
                fileUrl: "",
                flag: "1",
                forceUpdate: this.showIOSChecked,
                versionNum: this.form.ios.version,
                auditVersion: this.examineBtnStatus,
              }),
              resFunc: (res) => {
                if (res.data.code == 999) {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message,
                  });
                } else {
                  this.$message({
                    type: "success",
                    message: "修改成功!",
                  });
                  this.showIosVersionContent = false;
                  this.refresh();
                }
              },
              errFunc: (err) => {
                this.$notify.error({
                  title: "错误",
                  message: err,
                });
                console.log(err, "err1");
              },
            };
            request(opt);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消修改",
          });
        });
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.edition {
  height: calc(100% - 40px);
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  .edition_android,
  .edition_ios {
    width: 500px;
    height: 500px;
    box-shadow: 0 0 5px 0 rgba(7, 9, 85, 0.1);
    border-radius: 5px;
    overflow: hidden;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    position: relative;
    background: #fff;

    .content {
      color: #333;
      font-size: 16px;
      line-height: 50px;
      padding: 10px 0 0 30px;

      span {
        font-weight: normal;
      }

      .el-input,
      .el-textarea {
        width: 73%;

        ::v-deep .el-input__count {
          bottom: 1px !important;
        }
      }

      ::v-deep .el-checkbox__label {
        color: #333;
      }

      .el-checkbox {
        margin-top: 20px;
      }
    }

    .show_btn {
      .el-button--primary {
        background-image: linear-gradient(-129deg, #4186e7, #52b1f5);
      }
      .btn {
        width: 200px;
        position: absolute;
        bottom: 50px;
        left: calc(50% - 100px);
      }
    }

    .btn_showed {
      width: 100%;
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 50px;

      .el-button {
        width: 130px;
      }
    }
  }

  .android,
  .ios {
    width: calc(100% - 30px);
    height: 50px;
    line-height: 50px;
    padding-left: 30px;
  }

  .android {
    background: #4193e7ab;
  }
  .ios {
    background: #feb229ab;
  }
}
</style>
